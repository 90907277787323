<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;"
							@change="getAllCinemaList">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds"
							style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="goodsType" label="卖品类型">
						<a-select v-model:value="formState.goodsType" style="width: 190px;" placeholder="请选择卖品类型">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">普通卖品</a-select-option>
							<a-select-option :value="5">套餐卖品</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row v-show="showAll">
					<a-form-item name="goodsName" class="ui-form__item" label="卖品名称">
						<a-input v-model:value="formState.goodsName" placeholder="请输入卖品名称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item name="nickname" class="ui-form__item" label="会员昵称">
						<a-input v-model:value="formState.nickname" placeholder="请输入会员昵称"></a-input>
					</a-form-item>

					<a-form-item name="phone" class="ui-form__item" label="会员手机">
						<a-input v-model:value="formState.phone" placeholder="请输入会员手机"></a-input>
					</a-form-item>

				<!-- 	<a-form-item name="payType" class="ui-form__item" label="支付类型">
						<a-select v-model:value="formState.payType" style="width: 190px;" placeholder="请选择支付类型">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">微信支付</a-select-option>
							<a-select-option :value="2">卖品券</a-select-option>
							<a-select-option :value="3">混合支付</a-select-option>
							<a-select-option :value="4">后台核销</a-select-option>
						</a-select>
					</a-form-item> -->

					<a-form-item name="orderStatus" class="ui-form__item" label="核销状态">
						<a-select v-model:value="formState.orderStatus" style="width: 190px;" placeholder="请选择核销状态">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="5">未核销</a-select-option>
							<a-select-option :value="6">已核销</a-select-option>
							<a-select-option :value="4">已退款</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="日期" name="time">
						<a-date-picker v-model:value="formState.time" />
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['snack_report_sale_realtime_export']">
							<ExportReport type="snackStatisticsDetailRealtime"
								:searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2800 }"
					tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'week'">
							星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.createTime *1000).day()] }}
						</template>
						<template v-if="column.key === 'time'">
							<div>{{ transDateTime(record.createTime) }}</div>
						</template>
						
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime, 1) }}
						</template>

						<template v-if="column.key === 'orderInfo'">
							<p>订单号：<span style="color: #999;">{{ record.orderNo }}</span></p>
							<p>商户流水号：<span style="color: #999;">{{ record.outTradeNo }}</span></p>
						</template>
						
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-else> 抖音小程序 </a-tag>
							</div>
						</template>

						<template v-if="column.key === 'goodsInfo'">
							<div class="ui-goods" v-for="item in record.snackOrderItemVOS" :key="item.id">
								<div style="display: flex;align-items: flex-start;">
									<div style="margin-left: 8px;">
										<div style="font-size: 12px;">
											<a-tag v-if="item.goodsType === 1" color="blue">普通卖品</a-tag>
											<a-tag v-if="item.goodsType === 5" color="green">套餐卖品</a-tag>
											<a-tag v-if="item.cardBalance" style="padding: 2px;line-height: 12px;"
												color="orange" size="small">次卡</a-tag>
											{{ item.goodsTitle }}
										</div>
									</div>
								</div>
								<div v-if="item.cardBalance" style="margin-top: 4px;font-size: 12px;">
									<div>卡等级: {{ item.cardTitle }}</div>
									<div v-if="item.cardNumberList">卡号: 
										<span v-for="(subItem, subIndex) in item.cardNumberList" :key="subItem">
											{{ subItem }}
											<span v-if="subIndex < item.cardNumberList.length - 1">、</span>
										</span>
									</div>
									<div>观影次数: {{ item.cardBalance }}</div>
								</div>
							</div>
						</template>
						
						<template v-if="column.key === 'generalGoods'">
							<div class="ui-goods" v-for="item in record.snackOrderItemVOS" :key="item.id">
								<div style="display: flex;justify-content: space-between;" v-for="(subItem, i) in item.packageGoodsConfigVOS" :key="i">
									<span>{{ subItem.generalGoodsTitle }}</span>
									<span style="color: #999;">x{{ subItem.num }}</span>
								</div>
							</div>
						</template>
						
						<template v-if="column.key === 'type'">
							<a-tag style="margin-bottom: 8px;" :color="['#87d068', '#f50', '#108ee9', '#2db7f5'][record.payType - 1]">{{ ['现金支付', '卖品券', '混合支付', '后台核销'][record.payType - 1] }}</a-tag>
						</template>
						
						<template v-if="column.key === 'coupon'">
							<template v-for="item in record.snackOrderItemVOS" :key="item.id">
								<div v-if="item.snackUserCouponVOList && item.snackUserCouponVOList.length" class="ui-goods">
									券号：<span v-for="(subItem, subIndex) in item.snackUserCouponVOList" :key="subItem.barcode">
										{{ subItem.barcode }}
										<span v-if="subIndex < item.snackUserCouponVOList.length - 1">、</span>
									</span>
								</div>
								<div class="ui-goods" v-else>--</div>
							</template>
						</template>
						
						<template v-if="column.key === 'num'">
							<div class="ui-goods" v-for="item in record.snackOrderItemVOS" :key="item.id">
								{{ item.goodsNum }}
							</div>
						</template>
						
						<template v-if="column.key === 'pay'">
							<div class="ui-goods" v-for="item in record.snackOrderItemVOS" :key="item.id">
								{{ item.payment }}
							</div>
						</template>
						
						<template v-if="column.key === 'commission'">
							<div class="ui-goods" v-for="item in record.snackOrderItemVOS" :key="item.id">
								{{ item.commission }}
							</div>
						</template>
						
						<template v-if="column.key === 'deliveryType'">
							<a-tag color="cyan">自提</a-tag>
							<div v-if="record.verificationCode">核销码：{{ record.verificationCode }}</div>
						</template>
						
						<template v-if="column.key === 'status'">
							<span style="color: #fd6846;">{{ transStatus(record.status, record.deliveryType) }}</span>
							<div v-if="record.status !== 'AFTERSALE_FINISH'">
							    <div v-if="record.verificationUserName">核销人员：{{ record.verificationUserName }}</div>
							    <div v-if="record.finishTime">核销时间：{{ transDateTime(record.finishTime) }}</div>
							</div>
							<div v-else>
								<div>退款金额：￥{{ record.refund || 0 }}</div>
								<div>退款备注：{{ record.refundRemark || '--' }}</div>
							</div>
						</template>
						
						<template v-if="column.key === 'userInfo'">
							<div>会员昵称：{{ record.nickname || '--' }}</div>
							<div>会员手机：{{ record.phone || '-' }}</div>
						</template>
					</template>
					
					<template #summary v-if="list && list.length">
						<a-table-summary-row>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计： {{ total.goodsNum || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell>
								合计：￥{{ total.payment || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell>
								合计：￥{{ total.commission || 0 }}
							</a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { snackStatisticsDetailRealtime } from '@/service/modules/report.js';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import moment from 'moment';

	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				showAll: false,
				formState: {
					organizationId: '',
					cinemaIds: [0],
					goodsType: '',
					payType: '',
					orderStatus: '',
					source: 0,
					time: moment(new Date())
				},
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				columns: [{
						title: '日期',
						key: 'time',
						width: 140
					}, {
						title: '星期',
						key: 'week',
						width: 140
					}, {
						title: '所属影院',
						dataIndex: 'cinemaName'
					}, {
						title: '订单信息',
						key: 'orderInfo',
						width: 330,
					}, {
						title: '支付类型',
						key: 'type',
						width: 120,
						align: 'center'
					},  {
						title: '订单来源',
						key: 'source',
						width: 120,
						align: 'center'
					}, {
						title: '卖品信息',
						key: 'goodsInfo'
					}, {
						title: '单品信息',
						key: 'generalGoods'
					}, {
						title: '单品数量',
						key: 'num',
						align: 'center'
					}, {
						title: '实付金额(元)',
						key: 'pay',
						align: 'center'
					}, {
						title: '分销金额(元)',
						key: 'commission',
						align: 'center'
					}, {
						title: '卖品发放类型',
						key: 'deliveryType'
					}, {
						title: '核销状态',
						key: 'status'
					},
				],
				searchData: [],
				organizationList: [],
				cinemaAllList: [],
				total: {},
			}
		},
		created() {
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				if (!this.searchData.organizationId) {
					this.searchData.organizationId = undefined;
				}
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}


				if (this.searchData.time) {

					const nowTime = new Date(this.searchData.time)
					const startTime = new Date(nowTime.setHours(0, 0, 0, 0)); //获取当天零点的时间
					const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000 - 1); 
					
					this.searchData.startTime = this.moment(startTime).unix()
					this.searchData.endTime = this.moment(endTime).unix()

				}else{
					// 取当天时间
					const startTime = new Date(new Date().setHours(0, 0, 0, 0)); //获取当天零点的时间
					const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000 - 1); 

					this.searchData.startTime = this.moment(startTime).unix()
					this.searchData.endTime = this.moment(endTime).unix()
				}


				delete this.searchData.time;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.searchData = {};
				this.cinemaAllList = [];
				this.formState.cinemaIds = undefined;
				this.list = [];
				this.onSearch();
			},
			getForbiden(time){

				if(location.href.indexOf('admin.fenghe.gzlingren.com') < 0){
					return false;
				}else if(location.href.indexOf('debug') > 0){
					return false
				}else{
					return time < this.moment('2023-01-07').endOf('day').unix();
				}

			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let ret = await snackStatisticsDetailRealtime(postData);
					this.loading = false;

					if(this.getForbiden(postData.endTime)){
						// 不给看
						this.pagination.total = 0;
						this.total = {
							goodsNum: 0,
							payment: 0,
							commission: 0,
						};
						this.list = []
					}else{
						if (ret.code === 200) {
							this.pagination.total = ret.data.totalCount;
							this.total = ret.data.list[0];
							this.list = ret.data.list.splice(1);
						}
					}
					
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			getCinemaId(value) {
				this.cinemaIds = value;
			},
			disabledDate(current) {
                return current && current >= this.moment().endOf('day');
            },
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '已退款'
				}
				if (val === 'WAIT_COMMENT') {
					return '已签收'
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-goods {
		padding: 6px 10px;
		border-radius: 10px;
		border: solid 1px #EEE;
	}
	.ui-goods + .ui-goods {
		margin-top: 4px;
	}
</style>
